/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */


let leftMenu = [
  {
    id: 'settings-module',
    icon: 'mdi-cog',
    title: 'Settings',
    route: 'Settings'
  },
  {
    id: 'resources-module',
    title: 'Resources',
    icon: 'mdi-server',
    route: 'Resources'
  },
  {
    id: 'applications-module',
    title: 'Applications',
    icon: 'mdi-apps',
    route: 'Multi Tenancy'
  },
  {
    id: 'catalog-module',
    title: 'API Catalog',
    icon: 'mdi-api',
    route: 'API Catalog'
  }
];

module.exports = leftMenu;
